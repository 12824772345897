/* eslint-disable max-lines */
import CustomButton, { CustomButtonType } from '@/components/common/Button';
import PopUp from '@/components/common/Popup';
import { CustomController } from '@/components/form/controller';
// import { ImageInput } from '@/components/form/imageUploading';
import Input, { Label } from '@/components/form/input';
import { FormType } from '@/components/form/types';
import { COUNTRIES } from '@/constants/countries';
import useCustomerHook from '@/hooks/useCustomerHook';
import { VenueDetail, VenueDetailsForm } from '@/stores/slices/venue/type';
import {
  formatWorkHours,
  formatWorkingTime,
  getDefaultWorkHours,
  transformSelectOptions,
  transformSelectOptionsWithTranslation,
  transformVenueDetails,
} from '@/utils/helper';
import { Box, FormHelperText } from '@mui/material';
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { InputContainer, DeleteButton } from '.';
import {
  SectionWrapper,
  WeekdayFields,
} from '@/components/location/components/styled';
import CustomTimePicker from '@/components/form/timepicker';
import { useParams } from 'react-router-dom';
import { TimeIcon, DeleteIcon } from '@/components/icons';
import CheckBoxItem from '@/components/form/checkbox';
import { ReactComponent as CopyIcon } from '@/assets/icons/copy_ic.svg';
import { VENUE_TYPES } from '@/constants';
import SelectItem from '@/components/form/select';
import { useTranslation } from 'react-i18next';
import MapComponent from './map';

export interface VenuePopupProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (formdata: VenueDetailsForm) => void;
  onDelete?: () => void;
  data?: VenueDetail;
}

export interface VenueForm {
  name: string;
  contact: string;
  phone: string;
  email: string;
  province: string;
  district: string;
  ward: string;
  address: string;
}

export function VenuePopup({
  open,
  onClose,
  onSubmit,
  data,
  onDelete,
}: VenuePopupProps) {
  const params = useParams();
  const nameRef = useRef<any>(null);
  const countryRef = useRef<any>(null);
  const cityRef = useRef<any>(null);
  const addressRef = useRef<any>(null);
  const workRef = useRef<any>(null);
  const { t, i18n } = useTranslation();
  const [name, setName] = useState<string>('');

  const {
    control,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<VenueDetailsForm>({
    defaultValues: {
      name: '',
      address: '',
      city: '',
      countryCode: '',
      country: '',
      customer: params?.customerId ?? undefined,
      state: '',
      telephone: '',
      postal: '',
      timezone: '',
      publish: 0,
      accessKey: '',
      type: '',
      // originalLogo: '',
    },
  });

  const weekDayField = useFieldArray({
    name: 'workHours',
    control,
  });

  const onUpdateTime = useCallback(
    (value: any) => {
      const isInvalid = getValues('workHours').filter(
        it =>
          formatWorkingTime(it.open) >= formatWorkingTime(it.close) &&
          !!it.selected,
      );

      if (isInvalid?.length) {
        setError('workHours', {
          type: 'custom',
          message:
            'Invalid work hour. closing time should greater than opening time',
        });
      } else {
        clearErrors('workHours');
      }
    },
    [clearErrors, setError, getValues],
  );

  const submitForm = (formDt: any) => {
    onSubmit({
      ...formDt,
      customer: formDt.customer?.value ?? formDt.customer ?? params?.customerId,
      country: formDt?.country?.value ?? formDt?.country,
      // originalLogo: Array.isArray(formDt?.originalLogo)
      //   ? formDt?.originalLogo[0]?.data_url ?? null
      //   : formDt?.originalLogo ?? null,
      languages: ['en'],
      countryCode:
        _.get(
          _.find(
            COUNTRIES,
            it => it.name === (formDt?.country?.value ?? formDt?.country),
          ),
          'code',
        ) ?? '',
      workHours: formatWorkHours(formDt.workHours),
      externalId: toSnakeCase(name),
    });
    reset();
  };

  const onSelect = (lat: number, long: number) => {
    setValue('latitude', lat);
    setValue('longitude', long);
  };

  const onError = async () => {
    const ref = _.get(errors, [Object.keys(errors)[0]])?.ref;
    switch (ref.name) {
      case 'name':
      case 'customer':
        nameRef?.current?.scrollIntoView({ behavior: 'smooth' });
        break;

      case 'state':
      case 'country':
        countryRef?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'city':
      case 'postal':
        cityRef?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'address':
      case 'telephone':
        addressRef?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'workHours':
        workRef?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      default:
        nameRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const { customers, allCustomers } = useCustomerHook();

  useEffect(() => {
    if (data) {
      reset(transformVenueDetails(data));
    } else {
      reset({
        name: '',
        address: '',
        city: '',
        countryCode: '',
        country: '',
        state: '',
        telephone: '',
        postal: '',
        timezone: '',
        publish: 0,
        // originalLogo: '',
        accessKey: '',
        customer: params?.customerId ?? undefined,
        workHours: getDefaultWorkHours(),
      });
    }
  }, [data, reset, open, params?.customerId]);

  const toSnakeCase = (text: string) => {
    return text
      .replace(/([a-z])([A-Z])/g, '$1_$2')
      .replace(/([A-Z]+)([A-Z][a-z])/g, '$1_$2')
      .toLowerCase()
      .replace(/[\s-]+/g, '_');
  };

  return (
    <PopUp
      title={data ? t('Edit Venue') : t('Add Venue')}
      onClose={onClose}
      open={open}
    >
      <Box component="form" onSubmit={handleSubmit(submitForm, onError)}>
        <InputContainer className="single" ref={nameRef}>
          <Controller
            control={control}
            name="name"
            rules={{
              required: t('This field is required'),
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              setName(value);
              return (
                <Input
                  label={t('Name')}
                  isRequired
                  error={errors?.name?.message}
                  value={value}
                  onChange={onChange}
                />
              );
            }}
          />
        </InputContainer>

        <InputContainer className="single">
          <Controller
            control={control}
            name="externalId"
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <Input
                  label={t('ID')}
                  type="text"
                  value={toSnakeCase(name)}
                  onChange={onChange}
                  error={errors?.externalId?.message}
                  disabled
                />
              );
            }}
          />
        </InputContainer>

        <InputContainer>
          <CustomController
            controller={control}
            isRequired
            name="customer"
            label={t('Customer')}
            formType={FormType.MULTI_SELECT}
            isMulti={false}
            error={errors?.customer?.message}
            options={transformSelectOptions(allCustomers ?? [])}
            disabled={!!params?.customerId}
          />

          <Controller
            control={control}
            name="type"
            rules={{
              required: t('This field is required'),
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <SelectItem
                  isRequired
                  value={value ?? ''}
                  label={t('Type')}
                  options={transformSelectOptionsWithTranslation(
                    VENUE_TYPES,
                    t,
                  )}
                  onChange={onChange}
                  placeholder={t('Type')}
                  errorMess={errors?.type?.message}
                />
              );
            }}
          />
        </InputContainer>

        <InputContainer className="mt1 " ref={countryRef}>
          <CustomController
            controller={control}
            name="country"
            label={t('Country')}
            isRequired
            formType={FormType.MULTI_SELECT}
            isMulti={false}
            error={errors?.country?.message}
            options={transformSelectOptions(COUNTRIES ?? [], true)}
          />
          <Controller
            control={control}
            name="state"
            rules={{
              required: t('This field is required'),
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <Input
                  label={t('State')}
                  isRequired
                  error={errors?.state?.message}
                  value={value}
                  onChange={onChange}
                />
              );
            }}
          />
        </InputContainer>
        <InputContainer ref={cityRef}>
          <Controller
            control={control}
            name="city"
            rules={{
              required: t('This field is required'),
            }}
            render={({ field }) => {
              const { value, onChange } = field;

              return (
                <Input
                  label={t('City')}
                  isRequired
                  error={errors?.city?.message}
                  value={value}
                  onChange={onChange}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="postal"
            rules={{
              required: t('This field is required'),
              pattern: {
                value: /^[0-9]+$/i,
                message: 'Invalid postal code',
              },
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <Input
                  label={t('Postal code')}
                  value={value}
                  onChange={onChange}
                  isRequired
                  error={errors?.postal?.message}
                />
              );
            }}
          />
        </InputContainer>

        <InputContainer className="single" ref={addressRef}>
          <Controller
            control={control}
            name="address"
            rules={{
              required: t('This field is required'),
            }}
            render={({ field }) => {
              const { value, onChange } = field;

              return (
                <Input
                  label={t('Address')}
                  value={value}
                  onChange={onChange}
                  isRequired
                  error={errors?.address?.message}
                />
              );
            }}
          />
        </InputContainer>
        <InputContainer>
          <Controller
            control={control}
            rules={{
              required: t('This field is required'),
              pattern: {
                value: /^[0-9]+(-*)[0-9]+$/i,
                message: 'Invalid phone number',
              },
            }}
            name="telephone"
            render={({ field }) => {
              const { value, onChange } = field;

              return (
                <Input
                  label={t('Phone Number')}
                  value={value}
                  onChange={onChange}
                  isRequired
                  error={errors?.telephone?.message}
                />
              );
            }}
          />
        </InputContainer>
        {data && (
          <>
            <Box mt={1} ref={workRef}>
              <Label>{t('Work Hour')}</Label>
              {weekDayField.fields.map((item, fieldIndex) => {
                return (
                  <WeekdayFields key={item.id}>
                    <Controller
                      control={control}
                      name={`workHours.${fieldIndex}.selected` as const}
                      render={({ field }) => {
                        const { value, onChange } = field;
                        return (
                          <CheckBoxItem
                            onChange={val => {
                              onChange(val);
                              onUpdateTime(val);
                            }}
                            label={t(item.day)}
                            checked={!!value}
                            value={value}
                          />
                        );
                      }}
                    />

                    <SectionWrapper>
                      <Controller
                        control={control}
                        name={`workHours.${fieldIndex}.open` as const}
                        render={({ field }) => {
                          const { value, onChange } = field;
                          return (
                            <CustomTimePicker
                              onChange={val => {
                                onChange(val);
                                onUpdateTime(val);
                              }}
                              value={value}
                            />
                          );
                        }}
                      />
                      <TimeIcon />
                      <Controller
                        control={control}
                        name={`workHours.${fieldIndex}.close` as const}
                        render={({ field }) => {
                          const { value, onChange } = field;
                          return (
                            <CustomTimePicker
                              onChange={val => {
                                onChange(val);
                                onUpdateTime(val);
                              }}
                              value={value}
                            />
                          );
                        }}
                      />
                    </SectionWrapper>
                  </WeekdayFields>
                );
              })}
              {!!errors?.workHours?.message && (
                <FormHelperText
                  sx={{
                    marginTop: '10px',
                    color: '#e34a66',
                    fontSize: '10px',
                  }}
                >
                  {errors?.workHours?.message}
                </FormHelperText>
              )}
            </Box>
            <Box mt={1}>
              <InputContainer className="single">
                <Controller
                  control={control}
                  name="accessKey"
                  render={({ field }) => {
                    const { value, onChange } = field;

                    return (
                      <Box
                        sx={{
                          position: 'relative',
                          '& > svg': {
                            position: 'absolute',
                            top: '40px',
                            right: '10px',
                          },
                        }}
                      >
                        <Input
                          label={t('Access Key')}
                          value={value}
                          onChange={onChange}
                          error={errors?.accessKey?.message}
                          disabled
                        />

                        <CopyIcon
                          onClick={() => {
                            navigator.clipboard.writeText(value ?? '');
                          }}
                        />
                      </Box>
                    );
                  }}
                />
              </InputContainer>
            </Box>
          </>
        )}

        <InputContainer className="single">
          <Controller
            control={control}
            name="latitude"
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <Input
                  label={t('latitude')}
                  type="number"
                  value={value}
                  onChange={onChange}
                  error={errors?.latitude?.message}
                  // disabled
                />
              );
            }}
          />
        </InputContainer>
        <InputContainer className="single">
          <Controller
            control={control}
            name="longitude"
            render={({ field }) => {
              const { value, onChange } = field;

              return (
                <Input
                  label={t('Longitude')}
                  type="number"
                  value={value}
                  onChange={onChange}
                  // disabled
                  error={errors?.longitude?.message}
                />
              );
            }}
          />
        </InputContainer>
        <MapComponent
          latitude={data?.latitude ?? undefined}
          longitude={data?.longitude ?? undefined}
          onMarkerChange={onSelect}
        />

        {/* <Box mt={1}>
          <Controller
            control={control}
            name="originalLogo"
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <ImageInput
                  label="Logo"
                  title="Drag an image here or click “Upload” below"
                  width={120}
                  height={120}
                  onUpload={onChange}
                  value={value ? [value] : []}
                />
              );
            }}
          />
        </Box> */}
        <Box
          component="div"
          sx={{
            margin: '40px 0 0',
            display: 'grid',
            gridTemplateColumns:
              i18n.resolvedLanguage === 'ja' ? '150px 210px' : '150px 160px',
            justifyContent: 'space-between',
          }}
        >
          {data ? (
            <DeleteButton onClick={onDelete}>
              <DeleteIcon />
              {t('Delete venue')}
            </DeleteButton>
          ) : (
            <Box></Box>
          )}

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns:
                i18n.resolvedLanguage === 'en' ? '70px 70px' : '120px 70px',
              gap: '20px',
              alignSelf: 'end',
            }}
          >
            <CustomButton
              buttonType={CustomButtonType.WHITE_BG}
              title={t(`Cancel`)}
              onClick={onClose}
            />
            <CustomButton
              type="submit"
              buttonType={CustomButtonType.NO_ICON}
              title={t(`Submit`)}
            />
          </Box>
        </Box>
      </Box>
    </PopUp>
  );
}
